.clickable {
    cursor: pointer;
  }
  
  .container-size {
    height: calc(100vh - 80px - 38px); /* 56px header + 24px footer */
    width: calc(100vw - 56px); /* 56px side bar*/
    overflow: auto;
  }

  .inner-container-height {
    height: calc(100vh - 80px - 76px); /*Excluding the content header + top & bottom  padding area*/
  }

  .supervisor-container-height {
    height: calc(100vh - 310px);
  }

  .layout {
    position: relative;
  }
  
  .drawerPanel {
    div {
      width:  map-get($spacing, 8);
      height: map-get($spacing, 8);
      display: flex;
      justify-content: center;
      align-items: center;

      .mat-icon {
        width:  auto;
        height: auto;
        font-size: 16px;
      }

      img {
        height: 16px;
        width: 16px;
      }
    }
    div:hover {
      background: rgba(0, 121, 193, 0.12);
      border-radius: map-get($spacing, 10);
    }
  }

  .close-drawer{
    position: absolute;
    right: map-get($spacing, 4);
   }
   william-ui-platform-header {
    height: 56px;
    display: flex;
   }

   williams-ui-platform-re-captcha {
        .captchaHeight{
          @extend .container-size;
        }
   }

   .footer {
    bottom: 0;
    left: 56px;
    position: fixed;
   }

   .signUpFooter {
    @extend .footer;
    left: 0 !important
   }

   .alert-message-body{
    & > *{
      font-size: 12px;
      line-height: 16px;
      font-weight: 300;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  

  .app-tab-layout-wrapper {
    & > .k-tabstrip-content {
      height: calc(100vh - 56px - 38px - 24px);
      overflow: auto;
    }
  }


  .default-outlet {
    height: calc(100vh - 56px);
    overflow: auto;
  }

  .k-drawer-content {
    overflow: hidden;
  }

  .drawer-content-wrapper {
    height: calc(100vh - 80px); /* 56px header + 24px footer */
    width: calc(100vw - 56px); /* 56px side bar*/
  }


  williams-ui-platform-manage-alerts .k-height-container > div {
    height: 0px !important;
  }


  williams-ui-platform-nav-bar {
    .k-treeview-toggle + .k-treeview-leaf {
      padding-left: 0;
    }
  }
  
  williams-ui-platform-rate-escalation-terms {
.k-grid tbody>tr {
  &.k-selected {
    td, .k-grid-content-sticky {
      &::after {
        display: none;
      }
    }
  }
}
}
