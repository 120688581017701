williams-ui-platform-additional-access {
  williams-ui-platform-radio-button {
    kendo-label {
      margin-right: map-get($spacing, 2) !important;
    }
  }

  .page-wrapper-padding {
    position: relative;

    .form {
      width: 900px;
    }
  }

  .cancel-btn {
    position: absolute;
    left: 16px;
    top: 16px;
  }
}

williams-ui-platform-additional-access-success {
  .k-step-list-horizontal~.k-progressbar {
    top: 15px;
  }
}

williams-ui-platform-external-registration {
  .form-section-wrapper {
    max-width: 920px;
    width: 100%;
  }
}

williams-ui-platform-additional-access-success,
williams-ui-platform-registration-success {
  .page-wrapper {
    height: calc(100vh - 80px);
  }
  .success-card {
    max-width: 886px;
    width: 100%;
  }
}