williams-ui-platform-load-liquid-cashout-offset {
    .k-footer-template {
        background-color: $primary;
        .k-grid-footer-sticky {
            background-color: $primary;
        }

        .footer-aggregate {
            color: $white;
        }

        td:has(.footer-aggregate), td:has(.footer-aggregate) + td {
            border-left: 0;
        }
    }
}