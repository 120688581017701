  .sign-in-background-image {
    position: relative;
    margin: 1rem 2rem;
    width: calc(100vw - 22rem);
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(15, 34, 88, 0.04),
      0px 4px 16px rgba(15, 34, 88, 0.08);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    min-height: calc(100vh - 2rem);
    align-self: stretch;
  }

  .sign-in-box-wrapper {
    position: relative;
    left: 5rem;
    width: 30rem;
    margin: 1rem 0;
    z-index: 5;
  }

  @media only screen and (min-width: 430px) {
    .sign-in-box-wrapper {
      min-height: 700px;
    }
  }

  .sign-out-error-box {
    border: 1px solid #ffac2f;
    background: #ffdca8;
    color: #4e545a;
  }
