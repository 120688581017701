williams-ui-platform-delv-point-capacity-allocation {
  .k-footer-template {
    text-align: right;
  }
  williams-ui-platform-dpca-max-flow {
    .k-footer-template {
      pointer-events: all;
    }
  }
}
