/* You can add global styles to this file, and also import other style files */
@import '../../../../styles/base/variables/colors.scss';
@import '../../../../styles/base/fonts.scss';
@import '../../../../styles/base/mixins.scss';
@import '../../../../styles/base/configuration.scss';

//Libs styles
@import './assets/themes/default/variables';
@import '../../../../styles/base/common.scss';
@import './assets/themes/default/overrides';

html {
    height: 100%;
}
body{
    height: 100%;
    overflow: hidden;
    background: $page-bg;
}

williams-ui-platform-root {
    height: 100%;
}


.w-height-400{
    height: 400px
  }
  
