.nomination-overview-search-header,
.rank-receipt-overview-search-header,
.confirmation-search-criteria {
  & > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
.nomination-overview-search-header {
  williams-ui-platform-gathering-area-input,
  williams-ui-platform-company-input,
  williams-ui-platform-agreement-input,
  williams-ui-platform-cycle-input,
  .gas-date-input {
    width: calc((100% - 342px) / 5);
  }
}

.rank-receipt-overview-search-header {
  williams-ui-platform-company-input,
  williams-ui-platform-agreement-input,
  williams-ui-platform-cycle-input,
  .effective-date-input {
    width: calc(((100% - 313px) / 4));
  }
}

.confirmation-search-criteria {
  width: 100%;
  max-width: 1024px;
  williams-ui-platform-company-input,
  williams-ui-platform-cycle-input,
  .gas-date-input {
    width: calc(((100% - 172px) / 3) - 8px);
  }
}

.batch-upload-receipt-search-criteria {
  width: 50%;
  min-width: 676px;
  williams-ui-platform-company-input,
  .gas-date-input {
    width: calc((100% - 8px) / 2);
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.batch-upload-delivery-search-criteria {
  width: 50%;
  min-width: 800px;
  williams-ui-platform-company-input,
  williams-ui-platform-cycle-input,
  .gas-date-input {
    width: calc(((100% - 16px) / 3));
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.errors:empty {
  display: none;
}

williams-ui-platform-add-delivery-nomination {
  .add-nomination-search-header {
    max-width: 1400px;

    williams-ui-platform-gathering-area-input,
    williams-ui-platform-company-input,
    williams-ui-platform-agreement-input,
    williams-ui-platform-cycle-input,
    .gas-date-input {
      width: calc((100% - 132px) / 5);
    }
  }
}
williams-ui-platform-add-receipt-nomination {
  .add-nomination-search-header {
    max-width: 800px;

    williams-ui-platform-gathering-area-input,
    williams-ui-platform-company-input,
    .gas-date-input {
      width: calc((100% - 116px) / 3);
    }
  }
}
williams-ui-platform-add-receipt-itc-nomination {
  .add-nomination-search-header {
    max-width: 1200px;

    williams-ui-platform-gathering-area-input,
    williams-ui-platform-company-input,
    williams-ui-platform-agreement-input,
    .gas-date-input {
      width: calc((100% - 124px) / 4);
    }
  }
}

williams-ui-platform-add-delivery-nomination,
williams-ui-platform-add-receipt-nomination,
williams-ui-platform-add-receipt-itc-nomination {
  .add-nomination-search-header > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

williams-ui-platform-contacts {
  .contact-information-search-header {
    williams-ui-platform-stack-layout {
      width: 342px;
    }
  }
}

williams-ui-platform-notices, 
williams-ui-platform-alerts {
  .notice-search-criteria {
    williams-ui-platform-stack-layout {
      width: 173px;
    }
  }
}

williams-ui-platform-agreement-imbalance-search-criteria {
  .search-criteria {
    williams-ui-platform-stack-layout {
      width: 190px;
      &:last-of-type {
        width: 380px;
      }
    }

    > * {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

williams-ui-platform-past-five-days {
  .past-5-days-widget-search-header {
    .agreement-input, .station-input {
      width: 200px;
    }
  }
}

williams-ui-platform-reports-form{
.Date,.Dropdown,.Textbox-T  {
    width: 218px;
 }
// .radio {
// width: 100px;
// }
}
williams-ui-platform-search-ext-report {
 .search-criteria-width {
  width: calc((100% - 40%)/3 - 8px);
  }
}

.agreement-station-association-search-criteria {
  williams-ui-platform-stack-layout {
    max-width: 300px;
    flex-grow: 1;
  }
  williams-ui-platform-button {
    flex-grow: 0;
  }
}

.agreement-usage-search-criteria{
  williams-ui-platform-dropdown {
    width: 310px;
    display: block;
  }
}

.dpa-one-to-one-search-criteria {
  williams-ui-platform-dropdown {
    display: block;
    width: 310px;
  }
}

.condensate-plant-factors-search-criteria {
  williams-ui-platform-dropdown,  williams-ui-platform-datepicker {
    display: block;
    width: 270px;
  }
}

.gas-lift-station-association-search-criteria {
  williams-ui-platform-dropdown,  williams-ui-platform-datepicker, williams-ui-platform-autocomplete {
    display: block;
    width: 310px;
  }
}

williams-ui-platform-input-volume-adjustment, williams-ui-platform-measured-volume-adjustment {
  .search-criteria-date-width {
    max-width: 150px;
  }
  .search-criteria-width {
    width: 200px;
    max-width: 250px;
  }
}

williams-ui-platform-apply-view-terms {
  .apply-view-terms-search-criteria {
    williams-ui-platform-dropdown {
      display: block;
      width: 280px;
    }
  }

  williams-ui-platform-sidebar .k-window-content [content] {
    padding: 0;
  }
  
  .split-terms-header {
    williams-ui-platform-dropdown, p {
      min-width: 160px;
    }
    .vertical-bar {
      height: 28px;
      &.vertical-bar-lg {
        height: 48px;
      }
    }
  }
}

williams-ui-platform-maintain-station {
  .maintain-station-search-criteria {
    williams-ui-platform-text-input {
      display: block;
      width: 540px;
    }
  }

  .maintain-station-file-upload {
    williams-ui-platform-file-upload {
      display: block;
      width: 664px;
    }
  }

  .edit-station-form {
    gap: map-get($spacing, 3) map-get($spacing, 4);
    position: relative;
    .mask {
      position: absolute;
      z-index: 99;
      background-color: transparent;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    .label {
      flex-basis: 160px;
    }
    .input {
      flex-basis: 316px;
      width: 316px;
    }
  }
}
williams-ui-platform-maintain-delivery-point {
  .williams-ui-platform-maintain-delivery-point-search-criteria {
    williams-ui-platform-text-input {
      display: block;
      width: 540px;
    }
  }
  .edit-delivery-point-form {
    gap: map-get($spacing, 3) map-get($spacing, 4);
    position: relative;
    .mask {
      position: absolute;
    }
    .label {
      flex-basis: 160px;
    }
    .input {
      flex-basis: 316px;
      width: 316px;
    }
  }
}
 
williams-ui-platform-maintain-plant-factors {
  .maintain-plant-factors-search-criteria {
    williams-ui-platform-dropdown, williams-ui-platform-datepicker {
      display: block;
      width: 300px;
    }
  }

  williams-ui-platform-maintain-plant-factors-elections {
    display: block;
    max-width: 555px;
    min-width: 360px;

    .elections-form {
      overflow-y: auto;

      .form-field > {
        williams-ui-platform-dropdown,
        williams-ui-platform-switch {
          width: calc(50% - map-get($spacing, 1));
        }
      }
    }
  }
}

williams-ui-platform-rate-escalation-schedules {
  .maintain-plant-factors-search-criteria {
    williams-ui-platform-dropdown, williams-ui-platform-datepicker {
      display: block;
      width: 250px;
    }
  }

  williams-ui-platform-rate-escalation-schedule {
    display: block;
    max-width: 555px;
    min-width: 360px;

    .elections-form {
      overflow-y: auto;

      .form-field > {
        williams-ui-platform-dropdown,
        williams-ui-platform-switch {
          width: calc(50% - map-get($spacing, 1));
        }
      }
    }
  }
}

.change-receipt-agreement-search-criteria {
  williams-ui-platform-dropdown {
    display: block;
    width: 280px;
  }
  .vertical-bar-lg {
    height: 48px;
  }
}

.allocation-calculate-search-criteria{
  .vertical-bar-lg {
    height: 48px;
  }
}

williams-ui-platform-terms-gl-combo,williams-ui-platform-accounting-maintain-agmt-terms-search-criteria {
  .label-width {
    width: 260px;
    min-width: 260px;
  }
  .search-criteria-width {
    width: 240px;
    max-width: 240px;
  }
}

williams-ui-platform-terms-account-combo-tab {
  .label-width {
    width: 150px;
    min-width: 150px;
  }
  .search-criteria-width {
    width: 600px;
    max-width: 600px;
  }
}
williams-ui-platform-terms-datasource-tab {
  williams-ui-platform-dropdown {
  width: 210px;
  }
}

.input-oba-search-criteria{
  williams-ui-platform-dropdown {
    display: block;
    width: 240px;
  }
  .acctDate{
    min-width: 90px;
  }
  .status{
    min-width: 150px;
  }
}

williams-ui-platform-escalate-agreement-terms {
  .escalate-agreement-terms-search-criteria {
    williams-ui-platform-dropdown, williams-ui-platform-datepicker {
      display: block;
      width: 280px;
    }
    .vertical-bar-lg {
      height: 48px;
    }
  }

  .escalate-agreement-terms-search-review {
    williams-ui-platform-dropdown {
      display: block;
      width: 450px;
    }
    williams-ui-platform-datepicker {
      display: block;
      width: 280px;
    }
  }
}

.search-load-volume-gpm{
  williams-ui-platform-datepicker{
    min-width: 194px;
  }
  williams-ui-platform-dropdown{
    min-width: 344px;
  }
}

williams-ui-platform-maintain-agreement {
  .maintain-agreement-search-criteria {
    williams-ui-platform-text-input {
      display: block;
      width: 320px;
    }
  }
}

williams-ui-platform-agmt-review {
  .report-list-view {
    height: 300px;
  }
}

williams-ui-platform-generate-distribute-docs{
  williams-ui-platform-dropdown {
    min-width: 200px;
  }
  .sub-header-yellow{
    background-color: #EEC51A;
    color: black
  }
}
williams-ui-platform-agreement-search-criteria{
  .search-header {
    width: 200px;
  }
  .each-block{
    width: 125px;
  }
}

williams-ui-platform-interconnect-delivery-point {
  williams-ui-platform-interconnect-search-criteria{
    .date-picker-header {
      max-width: 140px;
      width: 140px;
    }
    .dropdown-header {
      max-width: 240px;
      width: 240px;
    }
  }
}
williams-ui-platform-common-liquids-voucher{
  williams-ui-platform-dropdown {
    min-width: 300px;
  }
}

williams-ui-platform-batman-tolerance {
  .sub-header-yellow{
    background-color: #EEC51A;
    color: black
  }
}

williams-ui-platform-agreement-summary {
  .sub-header-yellow{
    background-color: #EEC51A;
    color: black
  }
}

williams-ui-platform-interconnect-common-grid{
  williams-ui-platform-dropdown {
    min-width: 100px;
  }
}

williams-ui-platform-confirm-itc-receipt-search-criteria {
  .search-header-dropdown {
    width: 200px;
    max-width: 250px;  
  }
  .search-header-date-picker {
    width: 160;
    max-width:200px;  
  }
}