williams-ui-platform-web-reports{
    williams-ui-platform-web-reports-post-retract{
        .k-tabstrip-items-wrapper{
            width : 250px
        }

        .k-tabstrip{
            gap:16px;
        }
    }
    
}
