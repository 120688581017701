williams-ui-platform-interconnect-confirmations {
    .k-group-footer td:not(.k-group-cell) {
      background-color: $purple-16 !important;
    }
    .k-footer-template td:not(.k-group-cell) {
      @extend .k-footer-template;
      background-color: $primary;
      color: $white;
    }
  }

  williams-ui-platform-modify-group-allocation {
    th.maxWidthThreeChars span.k-column-title  {
      max-width: 3ch;
    }
  }
